














































































































































.balance-buttons{
  margin-left: 10px;
  font-size:13px !important;
}
.disabled-buttons{
  background-color: lightgrey;
  color: black;
}
.variation-button{
  margin-top: 50px;
  margin-left: 10px;
  font-size:13px !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  background-color: #51a351;
}
.table{
  margin-left: 10px;
}

#tablerisk{
  width: 100%;
  margin-bottom: 14px;
  clear: both;
}

.table{
  width: 100%;
  margin-bottom: 20px;
}

.table-bordered{
  border: 1px solid #ddd;
  border-collapse: separate;
  border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-top: 20px;
}
tr{
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

table tr{
  height: 30px;
}

.odd{
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em;
}

